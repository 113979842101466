<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Paid Amount List</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="merchant_id">Merchant</label>
                                            <v-select name="merchant_id"
                                                v-model="search.merchant_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= merchantList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="merchant_id">Payment Status</label>
                                            <v-select name="merchant_id"
                                                v-model="search.status"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= statusList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <div class="form-group">
                                            <label for="id">Payment ID</label>
                                            <input type="text" id="id" v-model="search.payment_id" placeholder="Payment ID" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="start_date">Start Date</label>
                                            <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="end_date">End Date</label>
                                            <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <div class="form-group">
                                            <div style="margin-top:28px;">
                                                <div v-if="loader" class="ml-1 spinner-border spinner-border-sm text-success" role="status" ></div>
                                                <button v-else class="btn btn-info btn-sm mr-1" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                                <a class="btn btn-warning btn-sm" target="_blank" :href="$baseUrl + 'excel-download/payment?start_date='+search.start_date+'&end_date='+search.end_date+'&status='+search.status+'&merchant_id='+search.merchant_id">
                                                    <i class="fa fa-download"></i> Excel
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                         <div class="form-group">
                                            <label>Today Paid(Tk) </label>
                                            <div v-if="loader" class="ml-1 spinner-border spinner-border-sm text-success" role="status" style="margin-top:28px;"></div>
                                            <input v-else type="text" v-model="todayPaid" class="form-control" readonly />
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-2">
                                         <div class="form-group">
                                            <label>Total Paid(Tk) </label>
                                            <div v-if="loader" class="ml-1 spinner-border spinner-border-sm text-success" role="status" style="margin-top:28px;"></div>
                                            <input v-else type="text" v-model="totalPaid" class="form-control" readonly />
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="paymentTable" class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Merchant</th>
                                                    <th>Payment Date</th>
                                                    <th>Payment By</th>
                                                    <th>Trnx ID</th>
                                                    <th>Status</th>
                                                    <th>Image</th>
                                                    <th>Total Order</th>
                                                    <th>Total Amount</th>
                                                    <th>Adjustment</th>
                                                    <th>Final Amount</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Merchant</th>
                                                    <th>Payment Date</th>
                                                    <th>Payment By</th>
                                                    <th>Trnx ID</th>
                                                    <th>Status</th>
                                                    <th>Image</th>
                                                    <th>Total Order</th>
                                                    <th>Total Amount</th>
                                                    <th>Adjustment</th>
                                                    <th>Final Amount</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(payment,key) in listData" :key="key">
                                                     <td>#BHK-P-{{ payment.id }}</td>
                                                    <td>{{ payment.merchant ? payment.merchant.business : '' }}</td>
                                                    <td>{{ payment.payment_date != null ? payment.payment_date : '' }}</td>
                                                    <td>{{ paymentBy(payment.payment_by) }}</td>
                                                    <td>{{ payment.tnxid != "null" ? payment.tnxid : '' }}</td>
                                                    <td :class="'status'+payment.status">{{ getStatus(payment.status) }}</td>
                                                    <td>
                                                        <a target="_blank" download="custom-filename.jpg" :href="$image_path+payment.image" title="ImageName">
                                                            <img v-if="payment.image != null" :src="$image_path+payment.image" style="width:60px;height:35px;"/>
                                                        </a>
                                                    </td>
                                                    <td>{{ payment.total_order }}</td>
                                                    <td>{{ payment.amount }}</td>
                                                    <td>{{ payment.adjustment }}</td>
                                                    <td>{{ payment.final_amount }}</td>
                                                    <td style="text-align:center;">  
                                                        <button class="btn btn-primary btn-sm mr-1" @click="showOrder(payment.id, payment.status)" title="Show Order"> <i class="fa fa-eye"></i> </button>       
                                                        <button v-if="payment.status == 1" class="btn btn-success btn-sm  mr-1" @click="payNow(key, payment)" title="Pay Now"><i class="fas fa-check"></i></button>
                                                        <button v-else class="btn btn-success btn-sm  mr-1" @click="invoice(payment.id)" title="Invoice"><i class="fas fa-file-invoice"></i></button>
                                                        <button v-if="authUser.role_id == 1 && (payment.payment_date <= beforeDate)" class="btn btn-danger btn-sm ml-1" @click="destroy(key, payment.id)" title="Delete"><i class="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="payNowModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Pay Now</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">   
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>
                                        <v-select name="merchant_id"
                                            v-model="pay_now.merchant_id"
                                            label="text"
                                            :reduce="item => item.id"
                                            :options= merchantList
                                            :placeholder="$t('globalTrans.select')"
                                        />
                                        <span class="text-danger" v-if="errors.merchant_id">{{ errors.merchant_id[0] }}</span>
                                        <input type="hidden" v-model="pay_now.id" />
                                    </div>                     
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="payment_by">Payment By <span class="text-danger" title="Required">*</span></label>
                                        <select id="payment_by" class="form-control" v-model="pay_now.payment_by">
                                            <option :value="1">Bkash</option>
                                            <option :value="2">Rocket</option>
                                            <option :value="3">Nagad</option>
                                            <option :value="4">Bank</option>
                                            <option :value="5">Cash</option>
                                            <option :value="6">Cheque</option>
                                        </select>
                                        <span class="text-danger" v-if="errors.payment_by">{{ errors.payment_by[0] }}</span>
                                    </div>                     
                                </div>
                            </div>   
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="amount">Order Amount </label>
                                        <input type="text" id="amount" v-model="pay_now.amount" class="form-control" readonly>
                                        <span class="text-danger" v-if="errors.amount">{{ errors.amount[0] }}</span>
                                    </div>                      
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="adjustment">Adjustment </label>
                                        <input type="text" id="adjustment" v-model="pay_now.adjustment" class="form-control" readonly>
                                        <span class="text-danger" v-if="errors.adjustment">{{ errors.adjustment[0] }}</span>
                                    </div>                      
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="final_amount">Final Amount </label>
                                        <input type="text" id="final_amount" v-model="pay_now.final_amount" class="form-control" readonly>
                                        <span class="text-danger" v-if="errors.final_amount">{{ errors.final_amount[0] }}</span>
                                    </div>                      
                                </div>
                            </div> 
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="end_date">Tnxid </label>
                                        <input type="text" id="tnxid" v-model="pay_now.tnxid" class="form-control" required>
                                        <span class="text-danger" v-if="errors.tnxid">{{ errors.tnxid[0] }}</span>
                                    </div>                      
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="image">Image </label>
                                        <input type="file" id="image" @change="onImageChange" class="form-control">
                                        <span class="text-danger" v-if="errors.image">{{ errors.image[0] }}</span>
                                    </div>                      
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="note">Note </label>
                                        <input type="text" id="note" v-model="pay_now.note" class="form-control">
                                        <span class="text-danger" v-if="errors.note">{{ errors.note[0] }}</span>
                                    </div>                      
                                </div>
                            </div>
                        </div>              
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="submitPayment">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>

        <div v-if="deleteModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyPayment">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    export default {
        name:'payment',
        data(){
            return {   
                loader: false,  
                todayPaid: 0,   
                totalPaid: 0,   
                beforeDate: moment().subtract(90,'d').format('YYYY-MM-DD'),
                statusList: [
                    { id:1, text: 'Pending'},
                    { id:2, text: 'Paid'}
                ],        
                payments:{
                    data: []
                },
                search: {
                    merchant_id: '',
                    status: '',
                    payment_id: '',
                    amount: '',
                    start_date: '',
                    end_date: ''
                },
                pay_now:{
                    id : '',
                    merchant_id : '',
                    payment_by : '',
                    amount : '',
                    adjustment : '',
                    final_amount : '',
                    tnxid : '',
                    note : '',
                    image : ''
                },
                form_edit:'',
                errors:[],
                payNowModal:false,
                deleteModal:false,
                payment_id:'',
                key:'',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created(){
            if (this.$route.query) {
                Object.assign(this.search, this.$route.query)
            } 
            this.loadData();
            this.loadTodayPaid();
            // this.loadTotalPaid();
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                }
            }
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            merchantList: function () {
                return this.$store.state.commonObj.merchantList
            }
        },
        methods:{
            onImageChange(e){
                this.pay_now.image = e.target.files[0];
            },
            searchData () {
                this.loadData()
                this.loadTodayPaid()
                this.loadTotalPaid()
            },
            async loadData(){    
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })       
                const response = await config.getData('/accounts/payment/paid', params)
                this.loader = false
                if (response.status == 200){
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            async loadTodayPaid(){    
                this.loader = true 
                const params = { merchant_id: this.search.merchant_id, payment_date: this.search.date }
                const response = await config.getData('/accounts/payment/today-paid', params)
                this.loader = false
                if (response.status == 200){
                    this.todayPaid = response.today_paid
                } else {
                    this.todayPaid = 0
                } 
            },
            async loadTotalPaid(){    
                this.loader = true 
                const response = await config.getData('/accounts/payment/paid-amount', this.search)
                this.loader = false
                if (response.status == 200){
                    this.totalPaid = response.total_paid
                } else {
                    this.totalPaid = 0
                } 
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            showOrder (paymentId, status) {
                this.$router.push({ name: 'show-order', query: { paymentId: paymentId, status: status } })
            },
            invoice (paymentId) {
                this.$router.push({ name: 'invoice', query: { paymentId: paymentId } })
            },
            payNow (key, payment) { 
                this.key = key;
                this.pay_now = payment;
                this.payNowModal = true;
                this.pay_now.final_amount = payment.final_amount
                document.body.classList.add("modal-open");
            },
            async submitPayment () {
                this.loader = true
                this.$store.dispatch('stateReload', true)

                var formData = new FormData();
                Object.keys(this.pay_now).map(key => {
                    if (key === 'image') {
                        formData.append(key, this.tmpImage)
                    } else {
                        formData.append(key, this.pay_now[key])
                    }
                })

                const response = await config.postData('/accounts/payment/pay-now', formData)
                this.loader = false
                
                if (response.status == 201) {
                    this.$store.dispatch('removeNavbarPaymentRequestList', response.merchant_id)
                    this.payNowModal = false         
                    this.$toast.success({
                        title: 'Success',
                        message: 'Paid successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            cancelModal(){
                this.createModal = false;
                this.editModal = false;
                this.deleteModal = false;
                this.payNowModal = false;
                document.body.classList.remove("modal-open");
            },
            destroy(key, payment_id) { 
                this.payment_id = payment_id;
                this.key = key;
                this.deleteModal = true;
            },
            async destroyPayment() {
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.deleteData('/accounts/payment/destroy/'+ this.payment_id)
                this.loader = false   
                if (response.status == '201') {      
                    this.deleteModal = false
                    this.$toast.success({
                        title: 'Success',
                        message: 'Payment deleted successfully',
                        color: '#218838'
                    }) 
                }  
                this.$store.dispatch('stateReload', false)
            },
            paymentBy (payment_by) {
                if (payment_by == 1) {
                    return 'Operation'
                }else if (payment_by == 2) {
                    return 'Merchant'
                } else {
                    return 'Accounts'
                }
            },
            getStatus (status) {
                if (parseInt(status) == 1) {
                    return 'Pending'
                }else {
                    return 'Paid'
                }
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
    .status1{
        color: #bfbf06;
        font-weight: bold;
    }
    .status2{
        color: green;
        font-weight: bold;
    }
    .imagePreviewWrapper {
        width: 250px;
        height: 250px;
        display: block;
        margin: 0 auto;
    }
</style>